module.exports = {
  member_benefit: "Become a member and enjoy many benefits",
  btn_sign_up: "SIGN UP",
  copyright: "© 2021 AYANA HOTEL. ALL RIGHTS RESERVED",
  footer_link_guest: "Guest Services",
  footer_link_tos: "Terms & Conditions",
  footer_link_faq: "FAQ",
  footer_link_point_policies: "Point Policies",
  footer_link_privacy_policies: "PRIVACY POLICY",
};
